import axios from "axios";
import handleApiError from "@/services/handleApiError";

export default {
    media(deputy_id) {
        return axios.get('/api/activity/media', {params: {deputy_id: deputy_id}}).then(resp => resp.data.data).catch(handleApiError);
    },
    mediaStats(deputy_id) {
        return axios.get('/api/activity/media-stats', {params: {deputy_id: deputy_id}}).then(resp => resp.data.data).catch(handleApiError);
    },
    attendance(deputy_id) {
        return axios.get('/api/activity/attendance', {params: {deputy_id: deputy_id}}).then(resp => resp.data.data).catch(handleApiError);
    },
    votes(deputy_id) {
        return axios.get('/api/activity/votes', {params: {deputy_id: deputy_id}}).then(resp => resp.data.data).catch(handleApiError);
    },
    get(deputy_id, type) {
        return axios.get('/api/activity', {params: {deputy_id: deputy_id, type: type}}).then(resp => resp.data.data).catch(handleApiError);
    },
    getUserActivities(type) {
        return axios.get('/api/activity/user-activities', {params: {type: type}}).then(resp => resp.data.data).catch(handleApiError);
    },
    getUserActivity(id, type) {
        return axios.get('/api/activity/user-activity', {params: {type: type, id: id}}).then(resp => resp.data.data).catch(handleApiError);
    },
    createUserActivity(form) {
        return axios.post('/api/activity/user-activity', form).then(resp => resp.data).catch(handleApiError);
    },
    updateUserActivity(form, id) {
        return axios.post('/api/activity/user-activity?id=' + id, form).then(resp => resp.data).catch(handleApiError);
    },
    deleteUserActivity(id) {
        return axios.get('/api/activity/user-activity', {params: {id: id, delete: 1}}).then(resp => resp.data).catch(handleApiError);
    }
}
