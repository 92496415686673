<template>
    <button
        class="tab-item"
        :class="{ active: currentId === id }"
        @click="$emit('change', id)"
        v-html="label"
    ></button>
</template>

<script>
export default {
    name: "TabHeaderItem",
    props: {
        currentId: {
            type: Number,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: true
        }
    }
}
</script>

<style lang="less">
    .tab-item{
        @media all and (min-width: 1600px) {
            br{
                display: none;
            }
        }
        @media all and (max-width: 950px) {
            br{
                display: none;
            }
        }
        @media all and (max-width: 400px) {
            br{
                display: block;
            }
        }
    }
</style>
<style scoped lang="less">
    .tab-item {
        height: 100%;
        background-color: #fff;
        font: 700 17px/20px @secondFontFamily;
        color: @blueColor;
        padding: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: background-color .3s linear, box-shadow .3s linear;
        cursor: pointer;
        border-radius: 10px;
        @media all and (max-width: 650px) {
            font-size: 16px;
        }
        &.active{
            background-color: @yellowColor;
            color: #fff;
            box-shadow: 0 24px 24px -14px fade(@yellowColor, 35%);
        }
    }
</style>
