<template>
    <div class="accordion">
        <div class="header">
            <slot name="header">HINT</slot>
            <img alt="" @click="" src="@/assets/images/edit.svg">
            <img alt="" src="@/assets/images/delete.svg">
            <i class="header-icon" v-on:click="toggle" v-bind:class="{ rotate: show }">
                <img alt="" src="@/assets/images/drop.svg">
            </i>
        </div>
        <transition name="accordion"
            v-on:enter="enter" v-on:leave="leave"
            v-on:before-enter="beforeEnter" v-on:before-leave="beforeLeave">
            <div v-show="show" class="body">
                <div class="body-inner">
                    <slot></slot>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            first: true
        };
    },
    methods: {
        toggle: function () {
            if(!this.show) {
                this.$emit('open');
            }
            if(this.first) {
                setTimeout(() => {this.show = !this.show;}, 200);
                this.first = false;
            }else{
                this.show = !this.show;
            }
        },
        beforeEnter: function (el) {
            el.style.height = '0';
        },
        enter: function (el) {
            el.style.height = el.scrollHeight + 'px';
        },
        beforeLeave: function (el) {
            el.style.height = el.scrollHeight + 'px';
        },
        leave: function (el) {
            el.style.height = '0';
        }
    }
}
</script>


<style lang="less" scoped>

.accordion {
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 2px;
}

.accordion .header {
    /*height: 40px;
    line-height: 40px;*/
    padding: 20px;
    position: relative;
    cursor: pointer;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.14px;
    color: #1A2744;
    font-weight: bold;
}

.accordion .header-icon {
    position: absolute;
    height: 14px;
    width: 24px;
    top: 27px;
    right: 20px;
    transform: rotate(90deg);
    transform-origin: center center;
    transition-duration: 0.3s;
}

.accordion .body {
    /*   display: none; */
    overflow: hidden;
    background-color: #fff;
    border-top: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    transition: 150ms ease-out;
}

.accordion .body-inner {
    padding: 0 20px 20px 20px;
    overflow-wrap: break-word;
    /*   white-space: pre-wrap; */
}

.accordion .header-icon.rotate {
    transform: rotate(180deg);
    top: 34px;

    // transform: translateY(-5px);
    transition-duration: 0.3s;
}

.accordion.white {
    background-color: #fff;
}

.accordion.white .body {
    border-color: #fff;
}

@media screen and (max-width: 1440px) {
    .accordion .header {
        font-size: 18px;
        line-height: 24px;
    }
}

</style>
