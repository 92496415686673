<template>
    <header>
        <ul>
            <li v-for="tab in tabs" :key="tab.id">
                <TabHeaderItem
                    :id="tab.id"
                    :label="tab.name"
                    :current-id="currentId"
                    @change="changeTab"
                />
            </li>
        </ul>
    </header>
</template>

<script>
    import TabHeaderItem from "./../TabHeaderItem";

    export default {
        name: "Tabs",
        components: {TabHeaderItem},
        props: {
            currentId: {
                type: Number,
                required: true
            },
            tabs: {
                type: Array,
                required: true
            }
        },
        methods: {
            changeTab(id) {
                this.$emit('change', id);
            }
        }
    }
</script>

<style scoped lang="less">
    header{
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0,0,0,.15);
        ul{
            width: 100%;
            display: flex;
            list-style: none;
            @media all and(max-width: 1000px) {
                display: block;
                li{
                    width: 100% !important;
                }
            }
            li{
                width: 33.3333%;
                button{
                    width: 100%;
                }
            }
        }
    }
</style>
