<template>
    <div class="wrapper profile">
        <CommonBlock
            title="Контроль"
            :visible-back-btn="true"
        >
            <div class="img">
                <img src="../assets/images/cabinet-img.svg" alt="cabinet">
            </div>
        </CommonBlock>
        <div class="content">
            <div class="content_bg">
                <header>
                    <tabs :tabs="tabs" :currentId="currentTab" @change="changeTab"></tabs>
                </header>
                <CustomButton label="Додати запис" @click="$router.push('/control/create?type=' + currentTab)"/>
                <div class="content-top">
                </div>
                <div class="content-body">
                    <AccordionCard v-for="(activity, index) in activities" :key="index" @open="">
                        <div slot="header">{{ getTitle(activity) }} {{ activity.activity_date | moment("DD.MM.YYYY") }}</div>
                        <div>{{ activity.description }}</div>
                    </AccordionCard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CommonBlock from "../components/CommonBlock";
    import Grid from "../components/ui/Grid";
    import Tabs from "../components/ui/Tabs";
    import AccordionCard from "../components/ui/AccordionCard";
    import CustomButton from "../components/CustomButton";
    import ActivityService from "../services/ActivityService";


    export default {
        name: 'control',
        components: {
            CommonBlock,
            Grid,
            Tabs,
            AccordionCard,
            CustomButton
        },
        data() {
            return {
                currentTab: 1,
                tabs: [{
                    id: 1,
                    name: 'Медійна діяльність'
                }, {
                    id: 4,
                    name: 'Ініціативи в раді'
                }, {
                    id: 5,
                    name: 'Акції'
                }],
                activities: []
            }
        },
        created() {
            this.loadActivities(this.currentTab);
        },
        methods: {
            changeTab(id) {
                this.currentTab = +id;
                this.loadActivities(this.currentTab);
            },
            async loadActivities(type) {
                this.activities = await ActivityService.getUserActivities(type);
            },
            getTitle(activity) {
                if(activity.type === 1) {
                    return activity.channel;
                }
                else {
                    return activity.title;
                }
            }
        }
    }
</script>


<style scoped lang="less">

    .btn-group {
        display: flex;
        justify-content: center;
        margin-bottom: 35px;
        @media screen and (max-width: 480px) {
            justify-content: space-between;
        }

        button {
            margin: 0 5px !important;
            width: 220px;
            height: 56px;

            svg {
                transform: scaleX(1.2);
            }

            &:after {
                top: 0;
            }

            @media screen and (max-width: 992px) {
                max-width: 180px;
                height: 46px;
                font-size: 16px;
                line-height: 20px;
                padding: 0 3px;
            }
            @media screen and (max-width: 768px) {
            }
            @media screen and (max-width: 375px) {
                max-width: 44%;
            }
        }

        .cancel-btn {
            svg {
                transform: scaleX(1.2) rotate(180deg);

                g {
                    fill: #cdd4e0;
                }
            }

            &:after {
                box-shadow: 0 24px 24px -14px #cdd4e080;
            }
        }
    }

    .wrapper {
        display: flex;
        @media all and(max-width: 992px) {
            display: block;
        }
    }

    .img {
        margin-bottom: 20px;
    }

    .text {
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.14px;
        color: #fff;
    }

    .content {
        padding: 80px 80px 80px 80px;
        width: 70%;
        height: 100vh;
        overflow-y: auto;
        @media all and(max-width: 1050px) {
            padding: 40px;
        }
        @media all and(max-width: 992px) {
            width: 100%;
            height: 100%;
        }
        @media all and(max-width: 480px) {
            padding: 20px 20px 50px 20px;
        }
    }

    .content {
        background-color: #F5F5F5;

        &-top {
            margin-bottom: 20px;

            .tops-filter {
                height: 60px;
                width: 100%;
                display: flex;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    bottom: -20px;
                    background-color: #ccc;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }

                & button {
                    // width: 33.3%;
                    width: 50%;
                    height: 100%;
                    background-color: #fff;
                    font-family: inherit;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 20px;
                    color: #1A2744;
                    padding: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    transition: all 0.3s ease-in;
                    cursor: pointer;
                    border-radius: 10px 10px 10px 10px;
                }

                .topActive {
                    transition: all 0.3s ease-in;
                    background-color: #ffc900;
                }
            }
        }
    }

</style>
